import React, { createContext, useContext, useState } from 'react';
import CatchRights from '../Models/Interfaces/CatchRights';

interface QuotaSelectionContextProps {
    selectedCatchRights: CatchRights[] | null;
    setSelectedCatchRights: React.Dispatch<React.SetStateAction<CatchRights[] | null>>;
}

const QuotaSelectionContext = createContext<QuotaSelectionContextProps | undefined>(undefined);

export const QuotaSelectionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [selectedCatchRights, setSelectedCatchRights] = useState<CatchRights[] | null>(null);

    return (
        <QuotaSelectionContext.Provider value={{ selectedCatchRights, setSelectedCatchRights }}>
            {children}
        </QuotaSelectionContext.Provider>
    );
};

export const useSelectedCatchRights = (): QuotaSelectionContextProps => {
    const context = useContext(QuotaSelectionContext);
    if (!context) {
        throw new Error('useSelectedCatchRights must be used within a QuotaSelectionProvider');
    }
    return context;
};

