import { TextField, styled } from '@mui/material';

const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.23)', 
        },
        '&:hover fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.5)', 
        },
        '&.Mui-focused fieldset': {
            borderColor: '#007bff', 
            borderWidth: '2px', 
        }
    },
    '& .MuiInputBase-input': {
        fontSize: '0.875rem', 
        padding: '12px', 
    }
});

export default StyledTextField;