import React, { createContext, useContext, useState } from 'react';

interface IsLoadingCatchRightsContextProps {
    isLoadingCatchRights: boolean;
    setIsLoadingCatchRights: React.Dispatch<React.SetStateAction<boolean>>;
}

const IsLoadingCatchRightsContext = createContext<IsLoadingCatchRightsContextProps | undefined>(undefined);

export const IsLoadingCatchRightsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    return (
        <IsLoadingCatchRightsContext.Provider value={{ isLoadingCatchRights: isLoading, setIsLoadingCatchRights: setIsLoading }}>
            {children}
        </IsLoadingCatchRightsContext.Provider>
    );
};

export const useIsLoadingCatchRights = (): IsLoadingCatchRightsContextProps => {
    const context = useContext(IsLoadingCatchRightsContext);
    if (!context) {
        throw new Error('isLoadingCatchRights must be used within an IsLoadingCatchRightsProvider');
    }
    return context;
};
