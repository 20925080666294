import React, { createContext, useContext, useState } from 'react';

interface ShowNegativeAflmarkContextProps {
    showNegativeAflmark: boolean;
    setShowNegativeAflmark: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShowNegativeAflmarkContext = createContext<ShowNegativeAflmarkContextProps | undefined>(undefined);

export const ShowNegativeAflmarkProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [showNegativeAflmark, setShowNegativeAflmark] = useState<boolean>(false);

    return (
        <ShowNegativeAflmarkContext.Provider value={{ showNegativeAflmark, setShowNegativeAflmark }}>
            {children}
        </ShowNegativeAflmarkContext.Provider>
    );
};

export const useSelectedhowNegativeAflmark = (): ShowNegativeAflmarkContextProps => {
    const context = useContext(ShowNegativeAflmarkContext);
    if (!context) {
        throw new Error('useSelectedhowNegativeAflmark must be used within a ShowNegativeAflmarkProvider');
    }
    return context;
};

