import React, { createContext, useContext, useState } from 'react';

interface IsLoadingShipsContextProps {
    isLoadingShips: boolean;
    setIsLoadingShips: React.Dispatch<React.SetStateAction<boolean>>;
}

const IsLoadingShipsContext = createContext<IsLoadingShipsContextProps | undefined>(undefined);

export const IsLoadingShipsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    return (
        <IsLoadingShipsContext.Provider value={{ isLoadingShips: isLoading, setIsLoadingShips: setIsLoading }}>
            {children}
        </IsLoadingShipsContext.Provider>
    );
};

export const useIsLoadingShips = (): IsLoadingShipsContextProps => {
    const context = useContext(IsLoadingShipsContext);
    if (!context) {
        throw new Error('useIsLoadingShips must be used within an IsLoadingShipsProvider');
    }
    return context;
};
