/* eslint-disable */
import { useEffect, useState, useMemo } from 'react';
import { AuthService } from '../Services/AuthService';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

const SignInOidc = () => {
  const authService = useMemo(() => new AuthService(), []);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [fatalError, setFatalError] = useState(false);

  useEffect(() => {
    const handleLoginChange = async () => {
      if (!sessionStorage.getItem('initialRender')) {
        sessionStorage.setItem('initialRender', 'true');
        try {
          setLoading(true);
          await authService.completeAuthentication();
          if (authService.isLoggedIn()) {
            navigate('/home');
          } else {
            authService.renewToken();
          }
        } catch (err) {
          setFatalError(true);
        } finally {
          setLoading(false);
        }
      } else {
        navigate('/home');
      }
    };
    handleLoginChange();
  }, []);


  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgcolor="#f4f4f4"
      gap={2}
    >
      {loading ? (
        <>
          <p>Skrái þig inn...</p>
          <CircularProgress />
        </>
      ) : fatalError ? (
        <Alert severity="error" sx={{ width: '50%', mt: 2 }}>
          Innskráning mistókst - vinsamlegast reynið aftur síðar.
        </Alert>)
        : (
          <>
            <p>Skrái þig inn...</p>
            <CircularProgress />
          </>
        )}
    </Box>
  );
};

export default SignInOidc;
