import React from 'react';
import { Typography, Box } from '@mui/material';
import { useMobile } from '../../Contexts/MobileContext';

interface TitleProps {
  title: string;
  color?: 'blue' | 'green';
}

const Title: React.FC<TitleProps> = ({ title, color = 'blue' }) => {
  const isMobile = useMobile();

  const getBackgroundColor = (color: 'blue' | 'green') => {
    switch (color) {
      case 'green':
        return '#E0F7EF';
      case 'blue':
      default:
        return '#E3F2FD';
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: getBackgroundColor(color),
        color: color === 'green' ? 'success.dark' : 'primary.dark',
        padding: isMobile ? '10px' : '20px',
        borderRadius: '4px',
        textAlign: 'center',
        marginBottom: '16px',
        boxShadow: 'none',
        border: `1px solid ${color === 'green' ? '#C8E6C9' : '#BBDEFB'}`,
      }}
    >
      <Typography
        variant={isMobile ? 'h5' : 'h4'}
        component="h1"
        sx={{ fontWeight: '600', letterSpacing: '0.5px' }} 
      >
        {title}
      </Typography>
    </Box>
  );
};

export default Title;
