import React, { useState } from 'react';
import { Card, CardContent, FormControl, FormControlLabel, Radio, RadioGroup, Typography, Box, Alert } from '@mui/material';
import StyledButton from '../../Styles/StyledButton';
import Title from '../Shared/Title';
import { GetCatchRights } from '../../Services/MillifaersluAPIService';
import CatchRights from '../../Models/Interfaces/CatchRights';
import { useYear } from '../../Contexts/YearContext';
import { useIsLoadingCatchRights } from '../../Contexts/IsLoadingCatchRightsContext';
import { useSelectedCatchRights } from '../../Contexts/QuotaSelectionContext';
import { useMobile } from '../../Contexts/MobileContext';

interface YearSelectionProps {
    lastFishYear: string;
    currentFishYear: string;
    activeStep: number;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
    setQuotaSelection: React.Dispatch<React.SetStateAction<CatchRights[] | null>>;
    fromShipNumber: number;
}

const YearSelection: React.FC<YearSelectionProps> = ({ lastFishYear, currentFishYear, setActiveStep, activeStep, setQuotaSelection, fromShipNumber }) => {
    const { currentYear, setCurrentYear } = useYear();
    const { setIsLoadingCatchRights } = useIsLoadingCatchRights();
    const { setSelectedCatchRights } = useSelectedCatchRights();
    const { isMobile } = useMobile();
    const [error, setError] = useState<string | null>(null);
    
    const handleYearSelection = (value: string) => {
        setCurrentYear(value === currentFishYear);
        fetchCatchRights(fromShipNumber || 0, value === currentFishYear);
    };

    const fetchCatchRights = async (shipNumber: number, currentYear: boolean) => {
        setIsLoadingCatchRights(true);
        setError(null);
        setSelectedCatchRights([]);
        try {
            const catchRights = await GetCatchRights(shipNumber, currentYear);
            setQuotaSelection(catchRights);
        } catch (err) {
            setError('Ekki tókst að sækja aflaheimildir frá miðlara. Vinsamlegast reyndu aftur síðar.');
        } finally {
            setIsLoadingCatchRights(false);
        }
    };

    const getInfoText = (value: string) => {
        if (value === lastFishYear) {
            return 'Ef valið er fyrrverandi fiskveiðiár verður millifærslan gerð á því fiskveiðiári.';
        } else if (value === currentFishYear) {
            return 'Ef valið er núverandi fiskveiðiár verður millifærslan gerð á því fiskveiðiári.';
        }
        return '';
    };

    const renderRadioCard = (value: string, label: string) => (
        <Card
            variant="outlined"
            sx={{ margin: '0.5rem 0', boxShadow: 3 }}
            onClick={() => handleYearSelection(value)}
        >
            <CardContent>
                <FormControlLabel
                    value={value}
                    control={<Radio checked={currentYear === (value === currentFishYear)} />}
                    label={<Typography variant="h6">{label}</Typography>}
                />
                <Typography variant="body2" color="textSecondary">
                    {getInfoText(value)}
                </Typography>
            </CardContent>
        </Card>
    );

    return (
        <Card variant="outlined" sx={{ padding: isMobile ? '5px' : '20px',  paddingBottom: isMobile ? '15px' : '20px', backgroundColor: 'white', boxShadow: 3, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            <Title title="Velja fiskveiðiár millifærslu " />
            <CardContent sx={{ flexGrow: 1, overflow: 'auto' }}>
                {error && <Alert severity="error"  sx={{ marginBottom: '10px' }}>{error}</Alert>}
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="fishing-year"
                        value={currentYear ? currentFishYear : lastFishYear}
                        name="radio-buttons-group"
                    >
                        {renderRadioCard(lastFishYear, lastFishYear)}
                        {renderRadioCard(currentFishYear, currentFishYear)}
                    </RadioGroup>
                </FormControl>
            </CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px' }}>
                <StyledButton
                    variant="contained"
                    onClick={() => setActiveStep(activeStep - 1)}
                >
                    Til baka
                </StyledButton>
                <StyledButton
                    variant="contained"
                    onClick={() => setActiveStep(activeStep + 1)}
                    disabled={!!error}
                >
                    Áfram
                </StyledButton>
            </Box>
        </Card>
    );
};

export default YearSelection;
