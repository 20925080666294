import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { BrowserRouter } from "react-router-dom";
import { YearProvider } from './Contexts/YearContext';
import { IsLoadingShipsProvider } from './Contexts/IsLoadingShipsContext';
import { IsLoadingCatchRightsProvider } from './Contexts/IsLoadingCatchRightsContext';
import { QuotaSelectionProvider } from './Contexts/QuotaSelectionContext';
import { ShowNegativeAflmarkProvider } from './Contexts/ShowNegativeAflmarkContext';
import { MobileProvider } from './Contexts/MobileContext';

document.body.style.backgroundColor = '#f5f5f5';

Sentry.init({
  dsn: "https://84b371b037d48998a1bedd446e01abfd@o4507412390346752.ingest.de.sentry.io/4507413399339088",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["https://millifaerslurdev.fiskistofa.is/", /^https:\/\/yourserver\.io\/api/, "localhost", "https://millifaerslurtest.fiskistofa.is/", "https://millifaerslur.fiskistofa.is/"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // Comment out strictMode to get rid of re-rendering
  // <React.StrictMode>
  <BrowserRouter>
    <MobileProvider>
      <IsLoadingShipsProvider>
        <IsLoadingCatchRightsProvider>
          <YearProvider>
            <QuotaSelectionProvider>
              <ShowNegativeAflmarkProvider>
                <App />
              </ShowNegativeAflmarkProvider>
            </QuotaSelectionProvider>
          </YearProvider>
        </IsLoadingCatchRightsProvider>
      </IsLoadingShipsProvider>
    </MobileProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
