import React, { useState } from 'react';
import { Menu, Avatar, MenuItem, Typography } from '@mui/material';
import { MdLogout, MdSwapHoriz, MdEmail } from 'react-icons/md';
import { UserNameCard, OrganizationNameCard, UserInfoCardContainer } from './Header.styled';
import EmailDialog from './EmailDialog';
import { User } from "oidc-client-ts";

interface UserMenuProps {
    anchorElUser: Element | null;
    handleCloseUserMenu: () => void;
    userInfo: User | null
    actorName: string | undefined;
    logOut: () => void;
    switchUser: () => void;
}


const UserMenu: React.FC<UserMenuProps> = ({ anchorElUser, handleCloseUserMenu, userInfo, actorName, logOut, switchUser }) => {
    const [isEmailDialogOpen, setEmailDialogOpen] = useState(false);

    const handleOpenEmailDialog = () => {
        setEmailDialogOpen(true);
        handleCloseUserMenu();
    };

    return (
        <>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <UserInfoCardContainer>
                    <Avatar sx={{ left: 'calc(50% - 2em)', height: '4em', width: '4em', bgcolor: 'black', marginBottom: '1em' }}>
                        {userInfo?.profile?.name?.charAt(0) ? userInfo?.profile?.name?.charAt(0) : 'N'}
                    </Avatar>
                    <OrganizationNameCard>{userInfo?.profile?.name}</OrganizationNameCard>
                    {actorName && <UserNameCard>{actorName}</UserNameCard>}
                </UserInfoCardContainer>
                <MenuItem onClick={handleOpenEmailDialog} style={{ marginTop: '1em' }}>
                    <MdEmail style={{ marginRight: '10px'}} />
                    <Typography textAlign="center">{'Bæta við netfangi'}</Typography>
                </MenuItem>
                <MenuItem onClick={switchUser} style={{ marginTop: '1em' }}>
                    <MdSwapHoriz style={{ marginRight: '10px'}} />
                    <Typography textAlign="center">Skipta um umboð</Typography>
                </MenuItem>
                <MenuItem onClick={logOut} style={{ marginTop: '1em' }}>
                    <MdLogout style={{ marginRight: '10px' }} />
                    <Typography textAlign="center">Skrá út</Typography>
                </MenuItem>
            </Menu>
            <EmailDialog 
                open={isEmailDialogOpen} 
                onClose={() => setEmailDialogOpen(false)} 
            />
        </>
    );
}

export default UserMenu;
