import React from 'react';
import { Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import CatchRights from '../../Models/Interfaces/CatchRights';

interface Props {
    selectedCatchRights: CatchRights[] | null;
}

const TransferTable: React.FC<Props> = ({ selectedCatchRights }) => {
    return (
        <>
            <Typography variant="h6" color="primary" gutterBottom sx={{ marginTop: '20px', fontWeight: 'bold' }}>
                Millifærslur
            </Typography>
            <TableContainer component={Paper} elevation={2} sx={{ flexGrow: 1 }}>
                <Table aria-label="catch rights table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Fisktegund</TableCell>
                            <TableCell align="right">Magn</TableCell>
                            <TableCell align="right">Verð</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedCatchRights?.map((right, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {right.name}
                                </TableCell>
                                <TableCell align="right">{`${right.quantity} ${right.unit === 1 ? 'kg' : 'tonn'}`}</TableCell>
                                <TableCell align="right">{right.kr} kr</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default TransferTable;
