import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { DirectionsBoat, Home, Business } from '@mui/icons-material';
import Ship from '../../Models/Interfaces/Ship';
import { useMobile } from "../../Contexts/MobileContext";

interface Props {
    companyShip: Ship | null;
    shipTo: Ship | null;
}

const ShipInfoGrid: React.FC<Props> = ({ companyShip, shipTo }) => {
    const isMobile = useMobile();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Paper elevation={2} sx={{ padding: isMobile ? '15px' : '20px', backgroundColor: '#fff' }}>
                    <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                        Frá
                    </Typography>
                    <Typography sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                        <DirectionsBoat fontSize="small" sx={{ marginRight: '8px' }} /> Skip: {companyShip?.name} ({companyShip?.shipNumber})
                    </Typography>
                    <Typography sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                        <Home fontSize="small" sx={{ marginRight: '8px' }} /> Heimahöfn: {companyShip?.homePort}
                    </Typography>
                    <Typography sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                        <Business fontSize="small" sx={{ marginRight: '8px' }} /> Rekstraraðili: {companyShip?.operatorName + ' (' + companyShip?.operatorId + ')'}
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Paper elevation={2} sx={{ padding: isMobile ? '15px' : '20px', backgroundColor: '#fff' }}>
                    <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                        Til
                    </Typography>
                    <Typography sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                        <DirectionsBoat fontSize="small" sx={{ marginRight: '8px' }} /> Skip: {shipTo?.name} ({shipTo?.shipNumber})
                    </Typography>
                    <Typography sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                        <Home fontSize="small" sx={{ marginRight: '8px' }} /> Heimahöfn: {shipTo?.homePort}
                    </Typography>
                    <Typography sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                        <Business fontSize="small" sx={{ marginRight: '8px' }} /> Rekstraraðili: {shipTo?.operatorName + ' (' + shipTo?.operatorId + ')'}
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default ShipInfoGrid;
