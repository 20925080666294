import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
interface SuccessMessageProps {
  referenceNumber: number;
  onClose: () => void;
}

const SuccessMessage: React.FC<SuccessMessageProps> = ({ referenceNumber, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <Box
      onClick={onClose}
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          padding: '2rem',
          borderRadius: '4px',
          textAlign: 'center',
        }}
      >
        <CheckCircleIcon style={{ fontSize: '4rem', color: 'green' }} />
        <Typography variant="h4" sx={{ marginTop: '1rem' }}>
          Millifærslan tókst!
        </Typography>
        <Typography variant="body1" sx={{ marginTop: '0.5rem' }}>
          Tilvísunarnúmer: {referenceNumber}
        </Typography>
      </Box>
    </Box>
  );
};

export default SuccessMessage;
