/* eslint-disable */
import {
  User,
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
} from "oidc-client-ts";

export class AuthService {
  public manager: UserManager;
  private user: User | null = null;

  constructor() {
    // https://github.com/IdentityModel/oidc-client-js/wiki
    // Sample here: https://github.com/island-is/identity-server.samples/tree/feature/adding-sample-projects
    const settings: UserManagerSettings = {
      // Issuer in Stjornbord.
      authority: process.env.REACT_APP_IDENTITY_SERVER_BASE_URL + '/',
      // ClientId in Stjornbord
      client_id: process.env.REACT_APP_CLIENT_ID ? process.env.REACT_APP_CLIENT_ID : '',
      // Callback URL after login with Audkenni.
      // Needs to be added to Callback URLs in Stjornbord.
      redirect_uri: process.env.REACT_APP_REDIRECT_URI ? process.env.REACT_APP_REDIRECT_URI : '',
      response_type: "code",
      loadUserInfo: false, // Have this as false. Otherwise duplication of actor information. Already getting actor information with profile scope.
      automaticSilentRenew: true,
      // Callback URL after logout.
      // Needs to be added to Logout URLs in Stjornbord.
      post_logout_redirect_uri: `https://island.is/s/fiskistofa`,
      // https://docs.devland.is/technical-overview/auth/scopes-and-tokens
      // Client ID from Stjornbord to be added here.
      // Profile: Request user profile-related information claims
      // offline_access: Request a Refresh Token with other tokens, which can be used to request new Access Tokens. 
      scope: 'openid profile offline_access ' + process.env.REACT_APP_CLIENT_ID,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      metadata: {
        // Token endpoint in the backend. Which is called to validate the token.
        token_endpoint: process.env.REACT_APP_BASE_API_URL + 'connect/token',
        authorization_endpoint: process.env.REACT_APP_IDENTITY_SERVER_BASE_URL + '/connect/authorize',
        // 'end_session_endpoint' is added so user logs out immediately after he logs out from the system.
        // (Not allowing user to sign out of the system and then sign in again without logging out from Audkenni)
        end_session_endpoint: process.env.REACT_APP_IDENTITY_SERVER_BASE_URL + '/connect/endsession',
        issuer: process.env.REACT_APP_IDENTITY_SERVER_BASE_URL
      }
    };
    this.manager = new UserManager(settings);

    // Checks if refreshing token resulted in a error because the refresh token has expired and logout the user.
    // Tokens lifetime can be manipulated here: https://island.is/stjornbord/
    this.manager.events.addSilentRenewError((error) => {
      this.user = null;
      this.manager.removeUser();
      this.logout();
    })
  }

  // Checks if user is logged in.
  isLoggedIn(): boolean {
    return this.user != null && !this.user.expired;
  }

  // Returns user if logged in.
  getUser(): Promise<User | null> {
    return this.manager.getUser();
  }

  // Returns authorization header value.
  getAuthorizationHeaderValue(user: User | null): string {
    return `${user?.token_type} ${user?.access_token}`;
  }

  login(): Promise<void> {
    return this.manager.signinRedirect();
  }

  completeAuthentication(): Promise<void> {
    return this.manager.signinRedirectCallback().then((user: User) => {
      this.user = user;
    });
  }

  // Renews token if expired.
  renewToken(): Promise<User | null> {
    return this.manager.signinSilent();
  }

  // Logs out user from the system.
  logout(): Promise<void> {
    // Clear initialRender on log out. Prevents redirect loop.
    sessionStorage.removeItem('initialRender');
    return this.manager.signoutRedirect();
  }

  logoutSilent(): void {
    this.manager.signoutSilent();
  }
}

/**
* This function is responsible for fetching and returning the user information.
* @returns {Promise<User | null>} - Returns a promise that resolves to a User object if the user is found; otherwise, resolves to null.
*/
export async function getUser(): Promise<User | null> {
  const authService = new AuthService();
  const user = await authService.getUser();
  if (user) {
    return user;
  } else {
    return null;
  }
}