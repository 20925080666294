import axios from "axios";
import Ship from "../Models/Interfaces/Ship";
import CatchRights from "../Models/Interfaces/CatchRights";
import { AuthService } from '../Services/AuthService';
import TransferHead from "../Models/Interfaces/TransferHead";
import Reference from "../Models/Interfaces/Reference";
import FishYear from "../Models/Interfaces/FishYear";
import FishYearsResponse from "../Models/Interfaces/FishYearsResponse";
import CatchRightsResponse from "../Models/Interfaces/CatchRightsResponse";
import ShipsResponse from "../Models/Interfaces/ShipsResponse";
import { AxiosError } from 'axios'

const authService = new AuthService();
const API_BASE_URL: string | undefined = process.env.REACT_APP_BASE_API_URL;

const errorHandler = (error: unknown, forbiddenRedirect = true) => {
    const axiosError = error as AxiosError;
    if (axiosError.response?.status === 401) {
        authService.login();
    } else if (axiosError.response?.status === 403) {
        if (forbiddenRedirect) {
            authService.logout();
        }
    }
}

export async function CreateUser(email: string): Promise<void> {
    try {
        const user = await authService.getUser();
        await axios.post(`${API_BASE_URL}user/email`, { email }, {
            headers: {
                Authorization: authService.getAuthorizationHeaderValue(user),
            },
        });
    } catch (error) {
        errorHandler(error);
        throw error;
    }
}

export async function UpdateUserEmail(email: string): Promise<void> {
    try {
        const user = await authService.getUser();
        await axios.put(`${API_BASE_URL}user/email`, { email }, {
            headers: {
                Authorization: authService.getAuthorizationHeaderValue(user),
            },
        });
    } catch (error) {
        errorHandler(error);
        throw error;
    }
}

export async function DeleteUserEmail(): Promise<void> {
    try {
        const user = await authService.getUser();
        await axios.delete(`${API_BASE_URL}user/email`, {
            headers: {
                Authorization: authService.getAuthorizationHeaderValue(user),
            },
        });
    } catch (error) {
        errorHandler(error);
        throw error;
    }
}

export async function GetUserEmail(): Promise<string | null> {
    try {
        const user = await authService.getUser();
        const response = await axios.get<string>(`${API_BASE_URL}user/email`, {
            headers: {
                Authorization: authService.getAuthorizationHeaderValue(user),
            },
        });
        return response.data;
    } catch (error) {
        errorHandler(error);
        throw error;
    }
}

export async function GetCompanyShips(): Promise<Ship[]> {
    try {
        const user = await authService.getUser();
        const response = await axios.get<ShipsResponse>(`${API_BASE_URL}ships/operator-ships`, {
            headers: {
                Authorization: authService.getAuthorizationHeaderValue(user)
            }
        });
        return response.data.ships;
    } catch (error) {
        errorHandler(error);
        throw error;
    }

}

export async function GetAllShips(): Promise<Ship[]> {
    try {
        const user = await authService.getUser();
        const response = await axios.get<ShipsResponse>(`${API_BASE_URL}ships`, {
            headers: {
                Authorization: authService.getAuthorizationHeaderValue(user)
            }
        });
        return response.data.ships;
    } catch (error) {
        errorHandler(error);
        throw error;
    }
}

export async function GetFishYears(): Promise<FishYear> {
    try {
        const user = await authService.getUser();
        const response = await axios.get<FishYearsResponse>(`${API_BASE_URL}ships/fish-years`, {
            headers: {
                Authorization: authService.getAuthorizationHeaderValue(user)
            }
        });
        return response.data.fishYear;
    } catch (error) {
        errorHandler(error);
        throw error;
    }
}

export async function GetCatchRights(shipNumber: number, currentYear: boolean): Promise<CatchRights[]> {
    try {
        const user = await authService.getUser();
        const response = await axios.get<CatchRightsResponse>(`${API_BASE_URL}ships/catch-rights?shipNumber=${shipNumber}&currentYear=${currentYear}`, {
            headers: {
                Authorization: authService.getAuthorizationHeaderValue(user)
            }
        });
        return response.data.catchRights;
    } catch (error) {
        errorHandler(error);
        throw error;
    }
}

export async function Verification(transfer: TransferHead): Promise<boolean | string> {
    try {
        const user = await authService.getUser();
        const response = await axios.post(`${API_BASE_URL}transfer/verification`, transfer, {
            headers: {
                Authorization: authService.getAuthorizationHeaderValue(user)
            },
            validateStatus: function (status) {
                return status === 200 || status === 400;
            }
        });
        if (response.status === 200) {
            return true;
        } else {
            return response.data;
        }
    } catch (error) {
        errorHandler(error);
        throw error;
    }
}

export async function TransferQuota(transfer: TransferHead): Promise<Reference> {
    try {
        const user = await authService.getUser();
        const response = await axios.post(`${API_BASE_URL}transfer`, transfer, {
            headers: {
                Authorization: authService.getAuthorizationHeaderValue(user)
            }
        });
        return response.data;
    } catch (error) {
        errorHandler(error);
        throw error;
    }
}
