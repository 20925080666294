import React, { useEffect, useState, useRef, useCallback } from 'react';
import Header from '../Header/Header';
import { GetAllShips, GetCompanyShips, GetFishYears } from '../../Services/MillifaersluAPIService';
import Ship from '../../Models/Interfaces/Ship';
import ShipSelection from '../ShipSelection/ShipSelection';
import QuotaSelection from '../QuotaSelection/QuotaSelection';
import { Box, Step, StepLabel, Stepper, Container, Alert, Typography, LinearProgress } from '@mui/material';
import CatchRights from '../../Models/Interfaces/CatchRights';
import ValidateTransfer from '../ValidateTransfer/ValidateTransfer';
import YearSelection from '../YearSelection/YearSelection';
import Reference from '../Reference/Reference';
import { useIsLoadingShips } from '../../Contexts/IsLoadingShipsContext';
import { useMobile } from '../../Contexts/MobileContext';

const Home: React.FC = () => {

    const truncateDate = (date: Date) => {
        const truncatedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        return truncatedDate;
      };

    // Function to determine if the carryover period is active. This is used to determine if the year selection step should be shown.
    const carryoverPeriod = (): boolean => {
        const truncatedDate = truncateDate(new Date());
        const start = new Date(truncatedDate.getFullYear(), 8, 1); // September 1st (month is 0-indexed)
        const end = new Date(truncatedDate.getFullYear(), 8, 15); // September 15th
        while (end.getDay() === 0 || end.getDay() === 6){ // If the end date is a weekend, move it to the next Monday
            end.setDate(end.getDate() + 1); // Move the end date to the next day
        }
        return truncatedDate >= start && truncatedDate <= end;;
    };

    const [companyShips, setCompanyShips] = useState<Ship[] | null>([]);
    const [allShips, setAllShips] = useState<Ship[] | null>([]);
    const [selectedFromShip, setSelectedFromShip] = useState<Ship | null>(null);
    const [selectedToShip, setSelectedToShip] = useState<Ship | null>(null);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [quotaSelection, setQuotaSelection] = useState<CatchRights[] | null>(null);
    const [previousFishYear, setPreviousFishYear] = useState<string>('');
    const [currentFishYear, setCurrentFishYear] = useState<string>('');
    const [referenceNumber, setReferenceNumber] = useState<number>(0);
    const [invoiceFile, setInvoiceFile] = useState<string>('');
    const [isTransferBetweenCompanies, setIsTransferBetweenCompanies] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const { setIsLoadingShips } = useIsLoadingShips();
    const { isMobile } = useMobile();
    const stepperRef = useRef<HTMLDivElement>(null);

    const showYearSelection = carryoverPeriod();

    const steps = [
        'Skipaval',
        ...(showYearSelection ? ['Fiskveiðiár'] : []),
        'Velja kvótategundir',
        'Millifæra',
        'Tilvísunarnúmer'
    ];

    const fetchData = useCallback(async () => {
        setIsLoadingShips(true);
        setError(null);
        try {
            const [companyShipsData, fishYears, allShipsData] = await Promise.all([GetCompanyShips(), GetFishYears(), GetAllShips()]);
            setCompanyShips(companyShipsData);
            setPreviousFishYear(fishYears.previousYear);
            setCurrentFishYear(fishYears.currentYear);
            setAllShips(allShipsData);
        } catch (err) {
            setError('Ekki tókst að sækja gögn frá miðlara. Vinsamlegast reyndu aftur síðar.');
        } finally {
            setIsLoadingShips(false);
        }
    }, [setIsLoadingShips]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (isMobile && stepperRef.current) {
            stepperRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [activeStep, isMobile]);



    return (
        <div>
            <Header />
            <Container style={{ paddingBottom: '3px', flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: isMobile ? 'calc(100vh - 60.8px)' : 'calc(100vh - 64px)' }}>
                {isMobile ? (
                    <Box marginTop={2} marginBottom={2} display="flex" justifyContent="center" ref={stepperRef}>
                        <Box>
                            <Typography variant="caption">
                                {`Skref ${activeStep + 1} af ${steps.length}`}
                            </Typography>
                            <LinearProgress
                                variant="determinate"
                                value={(activeStep + 1) * 100 / steps.length}
                            />
                        </Box>
                    </Box>
                ) : (
                    <Box marginTop={2} marginBottom={2}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                )}
                {error && <Alert severity="error" sx={{ marginBottom: '10px' }}>{error}</Alert>}
                {activeStep === 0 && (
                    <ShipSelection
                        companyShips={companyShips || []}
                        allShips={allShips || []}
                        companyShip={selectedFromShip}
                        toShip={selectedToShip}
                        setSelectedFromShip={setSelectedFromShip}
                        setSelectedToShip={setSelectedToShip}
                        setQuotaSelection={setQuotaSelection}
                        setIsTransferBetweenCompanies={setIsTransferBetweenCompanies}
                        setActiveStep={setActiveStep}
                        activeStep={activeStep}
                    />
                )}
                {showYearSelection ? (
                    <>
                        {activeStep === 1 && (
                            <YearSelection
                                lastFishYear={previousFishYear}
                                currentFishYear={currentFishYear}
                                setQuotaSelection={setQuotaSelection}
                                fromShipNumber={selectedFromShip?.shipNumber || 0}
                                setActiveStep={setActiveStep}
                                activeStep={activeStep}
                            />
                        )}
                        {activeStep === 2 && (
                            <QuotaSelection
                                companyShip={selectedFromShip}
                                shipTo={selectedToShip}
                                quotaSelection={quotaSelection}
                                setQuotaSelection={setQuotaSelection}
                                isTransferBetweenCompanies={isTransferBetweenCompanies}
                                setActiveStep={setActiveStep}
                                activeStep={activeStep}
                            />
                        )}
                        {activeStep === 3 && (
                            <ValidateTransfer
                                companyShip={selectedFromShip}
                                shipTo={selectedToShip}
                                setReferenceNumber={setReferenceNumber}
                                setInvoiceFile={setInvoiceFile}
                                setActiveStep={setActiveStep}
                                activeStep={activeStep}
                            />
                        )}
                        {activeStep === 4 && (
                            <Reference
                                referenceNumber={referenceNumber}
                                invoiceFile={invoiceFile}
                                shipTo={selectedToShip}
                                companyShip={selectedFromShip}
                                setSelectedFromShip={setSelectedFromShip}
                                setSelectedToShip={setSelectedToShip}
                                setActiveStep={setActiveStep}
                            />
                        )}
                    </>
                ) : (
                    <>
                        {activeStep === 1 && (
                            <QuotaSelection
                                companyShip={selectedFromShip}
                                shipTo={selectedToShip}
                                quotaSelection={quotaSelection}
                                setQuotaSelection={setQuotaSelection}
                                isTransferBetweenCompanies={isTransferBetweenCompanies}
                                setActiveStep={setActiveStep}
                                activeStep={activeStep}
                            />
                        )}
                        {activeStep === 2 && (
                            <ValidateTransfer
                                companyShip={selectedFromShip}
                                shipTo={selectedToShip}
                                setReferenceNumber={setReferenceNumber}
                                setInvoiceFile={setInvoiceFile}
                                setActiveStep={setActiveStep}
                                activeStep={activeStep}
                            />
                        )}
                        {activeStep === 3 && (
                            <Reference
                                referenceNumber={referenceNumber}
                                invoiceFile={invoiceFile}
                                shipTo={selectedToShip}
                                companyShip={selectedFromShip}
                                setSelectedFromShip={setSelectedFromShip}
                                setSelectedToShip={setSelectedToShip}
                                setActiveStep={setActiveStep}
                            />
                        )}
                    </>
                )}
            </Container>
        </div>
    );
};

export default Home;
