import { Button, styled, ButtonProps } from '@mui/material';

interface CustomButtonProps extends ButtonProps {
  customcolor?: 'green' | 'blue';
}

const StyledButton = styled(Button)<CustomButtonProps>(({ customcolor }) => ({
  backgroundColor: customcolor === 'green' ? '#28a745' : '#007bff',
  color: '#fff',
  '&:hover': {
    backgroundColor: customcolor === 'green' ? '#218838' : '#0056b3',
  },
  '&:disabled': {
    backgroundColor: '#ccc',
    color: '#888',
  },
  borderRadius: '4px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  zIndex: 1200,
}));

export default StyledButton;
