import React, { useState } from "react";
import {
    Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, InputAdornment,
    FormControlLabel, Switch, CircularProgress, Backdrop, Typography
} from "@mui/material";
import Ship from "../../Models/Interfaces/Ship";
import CatchRights from "../../Models/Interfaces/CatchRights";
import StyledButton from "../../Styles/StyledButton";
import StyledTextField from "../../Styles/StyledTextField";
import Title from "../Shared/Title";
import Alert from '@mui/material/Alert';
import { useIsLoadingCatchRights } from "../../Contexts/IsLoadingCatchRightsContext";
import { useSelectedCatchRights } from "../../Contexts/QuotaSelectionContext";
import { useSelectedhowNegativeAflmark } from "../../Contexts/ShowNegativeAflmarkContext";
import { useMobile } from '../../Contexts/MobileContext';

interface Props {
    companyShip: Ship | null;
    shipTo: Ship | null;
    quotaSelection: CatchRights[] | null;
    setQuotaSelection: React.Dispatch<React.SetStateAction<CatchRights[] | null>>;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
    activeStep: number;
    isTransferBetweenCompanies: boolean;
}

const QuotaSelection: React.FC<Props> = ({
    quotaSelection,
    setActiveStep,
    activeStep,
}) => {
    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const { isLoadingCatchRights } = useIsLoadingCatchRights();
    const { selectedCatchRights, setSelectedCatchRights } = useSelectedCatchRights();
    const { showNegativeAflmark, setShowNegativeAflmark } = useSelectedhowNegativeAflmark();
    const { isMobile } = useMobile();

    const handleInputChange = (event: React.FocusEvent<HTMLInputElement>, type: string, quotaType: number, name: string) => {
        setShowAlert(false);
        let value = parseFloat(event.target.value);
        if (isNaN(value)) {
            value = 0;
        }
        const updatedCatchRights = selectedCatchRights ? [...selectedCatchRights] : [];
        const index = updatedCatchRights.findIndex(x => x.quotaType === quotaType);

        if (isNaN(value)) {
            // Remove the catch right if the value is cleared.
            if (index !== -1) {
                updatedCatchRights.splice(index, 1);
            }
            event.target.value = "";
        } else {
            // Add or update the catch right.
            if (index === -1) {
                addNewCatchRight(updatedCatchRights, quotaType, name, value, type);
            } else {
                updateExistingCatchRight(updatedCatchRights, index, value, type);
            }
        }

        setSelectedCatchRights(updatedCatchRights);
    };

    const addNewCatchRight = (updatedCatchRights: CatchRights[], quotaType: number, name: string, value: number, type: string) => {
        const newCatchRight: CatchRights = {
            quotaType,
            name,
            quantity: type === 'quantity' ? value : 0,
            kr: type === 'kr' ? value : 0,
            unit: quotaSelection?.find(x => x.quotaType === quotaType)?.unit || 1
        };
        updatedCatchRights.push(newCatchRight);
    };

    const updateExistingCatchRight = (updatedCatchRights: CatchRights[], index: number, value: number, type: string) => {
        if (type === 'quantity') {
            updatedCatchRights[index].quantity = value;
        } else {
            updatedCatchRights[index].kr = value;
        }
    };

    const validateQuotaSelection = () => {
        if (!selectedCatchRights) return;

        selectedCatchRights.forEach((quotaItem, index) => {
            if (quotaItem.quantity === 0 && quotaItem.kr === 0) {
                selectedCatchRights.splice(index, 1);
            }
        });

        if (selectedCatchRights.some(quotaItem => quotaItem.quantity === 0 || quotaItem.quantity === null)) {
            setErrorMessage("Magn verður að vera stærra en 0.");
            setShowAlert(true);
            return;
        }

        if (selectedCatchRights.some(quotaItem => quotaItem.quantity.toString().length > 8)) {
            setErrorMessage("Magn má ekki vera meira en 8 tölustafir.");
            setShowAlert(true);
            return;
        }

        if (selectedCatchRights.some(quotaItem => quotaItem.kr && !/^\d{1,5}(\.\d{1,2})?$/.test(quotaItem.kr.toString()))) {
            setErrorMessage("Verð má aðeins innihalda 5 tölustafi á undan og 2 tölustafi á eftir punkt.");
            setShowAlert(true);
            return;
        }

        const updatedCatchRights = selectedCatchRights.filter(quotaItem =>
            quotaItem.quantity > 0
        );
        setSelectedCatchRights(updatedCatchRights);
        setActiveStep(activeStep + 1);
    }

    const handleKeyDown = (type: string) => (event: React.KeyboardEvent<HTMLInputElement>) => {
        const invalidKeys = ["-", "+", "e", "E"];
        if (type === 'quantity') {
            invalidKeys.push(".", ",");
        }
        if (invalidKeys.includes(event.key)) {
            event.preventDefault();
        }
    };

    return (
        <Card variant="outlined" sx={{ padding: isMobile ? '5px' : '20px', paddingBottom: isMobile ? '15px' : '20px', backgroundColor: 'white', boxShadow: 3, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            {isLoadingCatchRights && (
                <Backdrop style={{ zIndex: 1300, color: '#fff' }} open={isLoadingCatchRights}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            <Title title="Magn millifærslu" />
            {showAlert && (
                <Alert severity="error" sx={{ marginBottom: '10px' }}>
                    {errorMessage}
                </Alert>
            )}
            {(quotaSelection && quotaSelection.length === 0) && (
                <Alert severity="error" sx={{ marginBottom: '10px' }}>
                    Engar aflaheimildir fundust á skipinu sem valið var.
                </Alert>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '10px' }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={showNegativeAflmark}
                            onChange={() => setShowNegativeAflmark(!showNegativeAflmark)}
                            name="showNegativeAflmark"
                            color="primary"
                        />
                    }
                    label="Sýna neikvætt aflmark"
                />
            </Box>

            {isMobile ? (
                <Box>
                    {quotaSelection?.filter(x => x.quantity > 0).map((quota, index) => (
                        <Paper key={index} sx={{ padding: '10px', marginBottom: '10px' }}>
                            <Typography variant="subtitle1">{quota.name}</Typography>
                            <Typography variant="body2" sx={{ marginBottom: '10px' }}>
                                Aflamark: {`${quota.quantity} ${quota.unit === 1 ? 'kg' : 'tonn'}`}
                            </Typography>
                            <StyledTextField
                                type="number"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    style: { fontSize: '16px', height: '40px' },
                                    onBlur: (event: React.FocusEvent<HTMLInputElement>) => handleInputChange(event, 'quantity', quota.quotaType, quota.name),
                                    onKeyDown: handleKeyDown('quantity'),
                                    endAdornment: <InputAdornment position="end">{quota.unit === 1 ? 'kg' : 'tonn'}</InputAdornment>
                                }}
                                inputProps={{
                                    min: 0
                                }}
                                placeholder="Flutt magn"
                                defaultValue={selectedCatchRights?.find(x => x.quotaType === quota.quotaType)?.quantity.toString() || ""}
                                sx={{ marginBottom: '10px' }}
                            />
                            <StyledTextField
                                type="number"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    style: { fontSize: '16px', height: '40px' },
                                    onBlur: (event: React.FocusEvent<HTMLInputElement>) => handleInputChange(event, 'kr', quota.quotaType, quota.name),
                                    onKeyDown: handleKeyDown('kr'),
                                    endAdornment: <InputAdornment position="end">kr</InputAdornment>
                                }}
                                inputProps={{
                                    min: 0
                                }}
                                placeholder="Einingarverð"
                                defaultValue={selectedCatchRights?.find(x => x.quotaType === quota.quotaType)?.kr?.toString() || ""}
                            />
                        </Paper>
                    ))}
                    {showNegativeAflmark && quotaSelection?.filter(x => x.quantity <= 0).map((quota, index) => (
                        <Paper key={index} sx={{ padding: '10px', marginBottom: '10px', borderColor: '#BBDEFB', borderWidth: '2px', borderStyle: 'solid' }}>
                            <Typography variant="subtitle1">{quota.name}</Typography>
                            <Typography variant="body2" sx={{ marginBottom: '10px', color: '#1565c0' }}>
                                Aflamark: {`${quota.quantity} ${quota.unit === 1 ? 'kg' : 'tonn'}`}
                            </Typography>
                            <StyledTextField
                                type="number"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    style: { fontSize: '16px', height: '40px' },
                                    onBlur: (event: React.FocusEvent<HTMLInputElement>) => handleInputChange(event, 'quantity', quota.quotaType, quota.name),
                                    onKeyDown: handleKeyDown('quantity'),
                                    endAdornment: <InputAdornment position="end">{quota.unit === 1 ? 'kg' : 'tonn'}</InputAdornment>
                                }}
                                inputProps={{
                                    min: 0
                                }}
                                placeholder="Flutt magn"
                                defaultValue={selectedCatchRights?.find(x => x.quotaType === quota.quotaType)?.quantity.toString() || ""}
                                sx={{ marginBottom: '10px' }}
                            />
                            <StyledTextField
                                type="number"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    style: { fontSize: '16px', height: '40px' },
                                    onBlur: (event: React.FocusEvent<HTMLInputElement>) => handleInputChange(event, 'kr', quota.quotaType, quota.name),
                                    onKeyDown: handleKeyDown('kr'),
                                    endAdornment: <InputAdornment position="end">kr</InputAdornment>
                                }}
                                inputProps={{
                                    min: 0
                                }}
                                placeholder="Einingarverð"
                                defaultValue={selectedCatchRights?.find(x => x.quotaType === quota.quotaType)?.kr?.toString() || ""}
                            />
                        </Paper>
                    ))}
                </Box>
            ) : (
                <TableContainer component={Paper} elevation={2} sx={{ backgroundColor: 'white', overflowX: 'auto', flexGrow: 1 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Fisktegund</TableCell>
                                <TableCell align="left">Aflamark</TableCell>
                                <TableCell align="left">Flutt magn</TableCell>
                                <TableCell align="left">Einingarverð</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {quotaSelection?.filter(x => x.quantity > 0).map((quota, index) => (
                                <TableRow key={index}>
                                    <TableCell>{quota.name}</TableCell>
                                    <TableCell align="left">{`${quota.quantity} ${quota.unit === 1 ? 'kg' : 'tonn'}`}</TableCell>
                                    <TableCell align="right">
                                        <StyledTextField
                                            type="number"
                                            id={`${quota.quotaType}-${index}-quantity`}
                                            variant="outlined"
                                            fullWidth
                                            InputProps={{
                                                style: { fontSize: '16px', height: '40px' },
                                                onBlur: (event: React.FocusEvent<HTMLInputElement>) => handleInputChange(event, 'quantity', quota.quotaType, quota.name),
                                                onKeyDown: handleKeyDown('quantity'),
                                                endAdornment: <InputAdornment position="end">{quota.unit === 1 ? 'kg' : 'tonn'}</InputAdornment>
                                            }}
                                            inputProps={{
                                                min: 0
                                            }}
                                            defaultValue={selectedCatchRights?.find(x => x.quotaType === quota.quotaType)?.quantity.toString() || ""}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <StyledTextField
                                            type="number"
                                            variant="outlined"
                                            fullWidth
                                            InputProps={{
                                                style: { fontSize: '16px', height: '40px' },
                                                onBlur: (event: React.FocusEvent<HTMLInputElement>) => handleInputChange(event, 'kr', quota.quotaType, quota.name),
                                                onKeyDown: handleKeyDown('kr'),
                                                endAdornment: <InputAdornment position="end">kr</InputAdornment>
                                            }}
                                            inputProps={{
                                                min: 0
                                            }}
                                            defaultValue={selectedCatchRights?.find(x => x.quotaType === quota.quotaType)?.kr?.toString() || ""}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                            {showNegativeAflmark && quotaSelection?.filter(x => x.quantity <= 0).map((quota, index) => (
                                <TableRow key={index}>
                                    <TableCell>{quota.name}</TableCell>
                                    <TableCell align="left" style={{ color: '#1565c0' }}>{`${quota.quantity} ${quota.unit === 1 ? 'kg' : 'tonn'}`}</TableCell>
                                    <TableCell align="right">
                                        <StyledTextField
                                            type="number"
                                            id={`${quota.quotaType}-${index}-quantity`}
                                            variant="outlined"
                                            fullWidth
                                            InputProps={{
                                                style: { fontSize: '16px', height: '40px' },
                                                onInput: (event: React.FocusEvent<HTMLInputElement>) => handleInputChange(event, 'quantity', quota.quotaType, quota.name),
                                                endAdornment: <InputAdornment position="end">{quota.unit === 1 ? 'kg' : 'tonn'}</InputAdornment>
                                            }}
                                            defaultValue={selectedCatchRights?.find(x => x.quotaType === quota.quotaType)?.quantity.toString() || ""}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <StyledTextField
                                            type="number"
                                            variant="outlined"
                                            fullWidth
                                            InputProps={{
                                                style: { fontSize: '16px', height: '40px' },
                                                onInput: (event: React.FocusEvent<HTMLInputElement>) => handleInputChange(event, 'kr', quota.quotaType, quota.name),
                                                endAdornment: <InputAdornment position="end">kr</InputAdornment>
                                            }}
                                            defaultValue={selectedCatchRights?.find(x => x.quotaType === quota.quotaType)?.kr?.toString() || ""}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 'auto',
                paddingTop: '20px',
                gap: '0',
                position: 'sticky',
                bottom: 0,
                flexDirection: 'row',
            }}>
                <StyledButton
                    variant="contained"
                    onClick={() => {
                        setActiveStep(activeStep - 1);
                    }}
                    fullWidth={false}
                >
                    Til baka
                </StyledButton>
                <StyledButton
                    variant="contained"
                    onClick={validateQuotaSelection}
                    disabled={
                        !selectedCatchRights ||
                        selectedCatchRights.length === 0 ||
                        selectedCatchRights.every(right => right.quantity === 0 && right.kr === 0)}
                    fullWidth={false}
                >
                    Áfram
                </StyledButton>
            </Box>

        </Card>
    );
}

export default QuotaSelection;
