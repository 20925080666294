import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Typography, CircularProgress, Backdrop, Box, Snackbar, Alert, AlertColor } from '@mui/material';
import { UpdateUserEmail, DeleteUserEmail, CreateUser, GetUserEmail } from '../../Services/MillifaersluAPIService';
import { useMobile } from "../../Contexts/MobileContext";
import InfoIcon from '@mui/icons-material/Info';

interface EmailDialogProps {
    open: boolean;
    onClose: () => void;
}

const EmailDialog: React.FC<EmailDialogProps> = ({ open, onClose }) => {
    const [email, setEmail] = useState<string>('');
    const [initialEmailFetched, setInitialEmailFetched] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { isMobile } = useMobile();
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarType, setSnackbarType] = useState<AlertColor>('success');

    useEffect(() => {
        const fetchEmail = async () => {
            if (open) {
                setLoading(true);
                try {
                    const fetchedEmail = await GetUserEmail();
                    if (fetchedEmail) {
                        setEmail(fetchedEmail);
                        setInitialEmailFetched(true);
                    } else {
                        setInitialEmailFetched(false);
                    }
                } catch (error) {
                    setError("Tókst ekki að sækja netfang. Vinsamlegast reyndu aftur.");
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchEmail();
    }, [open]);

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSave = async () => {
        setError(null);

        if (!validateEmail(email)) {
            setError("Vinsamlegast sláðu inn gilt netfang.");
            return;
        }

        setLoading(true);
        try {
            if (initialEmailFetched) {
                await UpdateUserEmail(email);
            } else {
                await CreateUser(email);
            }
            setSnackbarType('success');
            setSnackbarOpen(true);
            handleClose();
        } catch (error) {
            setError(initialEmailFetched ? "Tókst ekki að uppfæra netfang. Vinsamlegast reyndu aftur." : "Tókst ekki að búa til notanda. Vinsamlegast reyndu aftur.");
            setSnackbarType('error');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        setLoading(true);
        setError(null);
        try {
            await DeleteUserEmail();
            setEmail('');
            setSnackbarType('success');
            setSnackbarOpen(true);
            handleClose();
        } catch (error) {
            setError("Tókst ekki að eyða netfangi. Vinsamlegast reyndu aftur.");
            setSnackbarType('error');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setError(null);
        setEmail('');
        onClose();
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{initialEmailFetched ? "Uppfæra netfang" : "Bæta við netfangi"}</DialogTitle>
                <DialogContent>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: isMobile ? '0px' : '10px',
                            marginBottom: '10px',
                            backgroundColor: '#f0f8ff',
                            padding: '10px',
                            borderRadius: '4px',
                            textAlign: 'center'
                        }}
                    >
                        <InfoIcon sx={{ marginRight: '8px', color: '#1976d2' }} />
                        <Typography
                            sx={{
                                color: '#212529',
                                fontWeight: 'bold',
                                fontSize: isMobile ? '14px' : '18px',
                            }}
                        >
                            Netfangið er tengt þínum rafrænu skilríkjum og allar millifærslur sem þú framkvæmir verða sendar þangað.
                        </Typography>
                    </Box>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Netfang"
                        type="email"
                        fullWidth
                        variant="standard"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={loading}
                        error={!!error}
                        helperText={error}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={loading}>Hætta við</Button>
                    {initialEmailFetched && (
                        <Button onClick={handleDelete} disabled={loading} color="secondary">
                            Eyða
                        </Button>
                    )}
                    <Button onClick={handleSave} disabled={loading || !email.trim()}>
                        {initialEmailFetched ? "Uppfæra" : "Staðfesta"}
                    </Button>
                </DialogActions>
                <Backdrop
                    style={{ zIndex: 1300, color: '#fff' }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarType} sx={{ width: '100%' }}>
                    {snackbarType === 'success' ? 'Aðgerð tókst' : 'Villa kom upp!'}
                </Alert>
            </Snackbar>
        </>
    );
};

export default EmailDialog;
