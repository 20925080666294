import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { useState, useEffect } from 'react';
import { Avatar, Badge, styled } from '@mui/material';
import { OrganizationNameHeader, UserInfoHeaderContainer, StyledLogo } from './Header.styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AuthService, getUser } from '../../Services/AuthService';
import { User } from "oidc-client-ts";
import { useNavigate } from "react-router-dom";
import { useTheme, useMediaQuery } from '@mui/material';
import UserMenu from './UserMenu';

function Header() {

    const [userInfo, setUserInfo] = useState<User | null>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    const getDisplayName = () => {
        const userName = userInfo?.profile?.name;
        const actorName = (userInfo?.profile?.actor as { name?: string })?.name;

        // If there's an actor name, display it alongside the user's name
        if (actorName) {
            return `${userName} (${actorName})`;
        }

        // Default to just the user name
        return userName;
    };

    useEffect(() => {
        async function fetchUser() {
            const user = await getUser();
            if (user === null) {
                navigate("/");
            }
            setUserInfo(user);
        }
        fetchUser();
    }, [navigate]);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const SmallAvatar = styled(Avatar)(() => ({
        width: 8,
        height: 8,
        backgroundColor: '#3a3b3c',
        border: '4px solid #3a3b3c'
    }));

    const logOut = () => {
        const authService = new AuthService();
        authService.logout();
    };

    const switchUser = () => {
        const authService = new AuthService();
        sessionStorage.removeItem('initialRender');
        authService.login();
    };

    return (
        <AppBar position="static" sx={{ padding: isMobile ? '0.3em 0.3em 0em' : '0em 0.3em' }}>
            <Toolbar sx={{ justifyContent: 'space-between', flexDirection: 'row' }}>

                {isMobile ? (
                    // Mobile Layout
                    <>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                            <StyledLogo
                                src="https://images.ctfassets.net/8k0h54kbe6bj/3W68zG8kVqVY7DU1qHxVEA/4d403503b05488cc8385578a92ef399e/fiskist-merki_2x.png"
                                alt="logo"
                                $isMobile={isMobile}
                            />
                            <Typography variant="h6" sx={{ fontSize: '1rem', color: 'white', textAlign: 'center', flexGrow: 1 }}>
                                Millifærslukerfi
                            </Typography>
                            <Box>
                                <Tooltip title="">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <Badge
                                            overlap="circular"
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            badgeContent={
                                                <SmallAvatar>
                                                    <ExpandMoreIcon sx={{ fontSize: '0.8rem' }} />
                                                </SmallAvatar>
                                            }
                                        >
                                            <Avatar sx={{ height: '2em', width: '2em', bgcolor: 'black', fontSize: '0.65em', border: '2px solid black' }}>
                                                {userInfo?.profile?.name?.charAt(0) || 'N'}
                                            </Avatar>
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </>
                ) : (
                    // Desktop Layout
                    <>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <StyledLogo src="https://images.ctfassets.net/8k0h54kbe6bj/3W68zG8kVqVY7DU1qHxVEA/4d403503b05488cc8385578a92ef399e/fiskist-merki_2x.png" alt="logo" $isMobile={isMobile} />
                            <Typography variant="h6" sx={{ paddingLeft: '0.4em' }}>Fiskistofa - Millifærslukerfi</Typography>
                        </Box>
                        <Box>
                            <Tooltip title="">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <UserInfoHeaderContainer>
                                        <OrganizationNameHeader $isMobile={isMobile}>
                                            {getDisplayName()}
                                        </OrganizationNameHeader>
                                    </UserInfoHeaderContainer>
                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        badgeContent={
                                            <SmallAvatar>
                                                <ExpandMoreIcon />
                                            </SmallAvatar>
                                        }
                                    >
                                        <Avatar sx={{ height: '2em', width: '2em', bgcolor: 'black', fontSize: '0.65em', border: '2px solid black' }}>{userInfo?.profile?.name?.charAt(0) ? userInfo?.profile?.name?.charAt(0) : 'N'}</Avatar>
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </>
                )}
                <UserMenu
                    anchorElUser={anchorElUser}
                    handleCloseUserMenu={handleCloseUserMenu}
                    userInfo={userInfo}
                    actorName={(userInfo?.profile?.actor as { name?: string })?.name}
                    logOut={logOut}
                    switchUser={switchUser}
                />
            </Toolbar>
        </AppBar>
    );
}

export default Header;