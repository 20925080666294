import React, { createContext, useContext, useState } from 'react';

interface YearContextProps {
    currentYear: boolean;
    setCurrentYear: React.Dispatch<React.SetStateAction<boolean>>;
}

const YearContext = createContext<YearContextProps | undefined>(undefined);

export const YearProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [currentYear, setCurrentYear] = useState<boolean>(true);

    return (
        <YearContext.Provider value={{ currentYear, setCurrentYear }}>
            {children}
        </YearContext.Provider>
    );
};

export const useYear = (): YearContextProps => {
    const context = useContext(YearContext);
    if (!context) {
        throw new Error('useYear must be used within a YearProvider');
    }
    return context;
};
