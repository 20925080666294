import { Route, Routes, Navigate } from 'react-router-dom';
import Login from './Components/Login';
import Home from './Components/Home/Home';
import SignInOidc from './Components/SignInOidc';
import './App.css';

function App() {
  return (
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/home" element={<Home />}></Route>
        <Route path="/signin-oidc" element={<SignInOidc />}></Route>
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
  );
}

export default App;