import React, { createContext, useContext } from 'react';
import { useMediaQuery } from '@mui/material';

interface MobileContextType {
    isMobile: boolean;
}

const MobileContext = createContext<MobileContextType | undefined>(undefined);

export const MobileProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <MobileContext.Provider value={{ isMobile }}>
            {children}
        </MobileContext.Provider>
    );
};

export const useMobile = (): MobileContextType => {
    const context = useContext(MobileContext);
    if (context === undefined) {
        throw new Error('useMobile must be used within a MobileProvider');
    }
    return context;
};
